import React, { useState, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"

const styles = {
    container: {
        width: "100%",
        fontFamily: "Figtree, sans-serif",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        overflow: "visible",
    },
    itemContainer: {
        cursor: "pointer",
        display: "flex",
        alignItems: "flex-start",
        gap: "20px",
        position: "relative", // Add this
        overflow: "visible",
    },
    iconContainer: {
        width: "57px",
        height: "57px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        position: "relative",
        padding: "11px",
        boxSizing: "border-box",
        overflow: "visible",
        zIndex: 1, // Add this
    },
    contentContainer: {
        flex: 1,
        paddingTop: "11px",
        overflow: "visible",
    },
    heading: {
        fontSize: "24px",
        fontWeight: "bold",
        lineHeight: "130%",
        letterSpacing: "-0.01em",
        marginBottom: "10px",
        transition: "color 0.3s ease",
    },
    content: {
        fontSize: "18px",
        fontWeight: "normal",
        lineHeight: "28px",
        letterSpacing: "-0.02em",
        margin: "0",
        paddingTop: "4px",
    },
}

const GlobeIcon = ({ color }) => (
    <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.5 0.75C26.6127 0.75 34 8.13731 34 17.25C34 26.3627 26.6127 33.75 17.5 33.75C8.38731 33.75 1 26.3627 1 17.25C1 8.13731 8.38731 0.75 17.5 0.75Z"
            stroke={color}
            strokeWidth="1.5"
        />
        <path
            d="M17.5 0.75C21.0563 0.75 24.5 8.13731 24.5 17.25C24.5 26.3627 21.0563 33.75 17.5 33.75C13.9437 33.75 10.5 26.3627 10.5 17.25C10.5 8.13731 13.9437 0.75 17.5 0.75Z"
            stroke={color}
            strokeWidth="1.5"
        />
        <path d="M2.91666 13H32.0833" stroke={color} strokeWidth="1.5" />
        <path d="M2.91666 21.5H32.0833" stroke={color} strokeWidth="1.5" />
    </svg>
)

const LoadingCircle = ({ progress }) => (
    <svg
        width="57"
        height="57"
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: "absolute", top: 0, left: 0 }}
    >
        <circle
            cx="28.5"
            cy="28.5"
            r="27.75"
            stroke="#B9BCC1"
            strokeWidth="1.5"
        />
        <motion.path
            d="M28.5 0.75C44.2401 0.75 57 13.5099 57 29.25C57 44.9901 44.2401 57.75 28.5 57.75C12.7599 57.75 0 44.9901 0 29.25C0 13.5099 12.7599 0.75 28.5 0.75Z"
            stroke="#6633BB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeDasharray="180.64"
            initial={{ strokeDashoffset: 180.64 }}
            animate={{ strokeDashoffset: 180.64 * (1 - progress) }}
        />
    </svg>
)

export function BenefitComponent({ data }) {
    const [activeIndex, setActiveIndex] = useState(0)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 1) {
                    setActiveIndex((prevIndex) => (prevIndex + 1) % data.length)
                    return 0
                }
                return prevProgress + 0.02
            })
        }, 100)

        return () => clearInterval(interval)
    }, [data.length])

    const handleItemClick = (index) => {
        setActiveIndex(index)
        setProgress(0)
    }

    return (
        <div style={styles.container}>
            {data.map((item, index) => {
                const isActive = index === activeIndex
                const iconColor = isActive ? "#6633BB" : "#B9BCC1"
                return (
                    <div
                        key={index}
                        style={styles.itemContainer}
                        onClick={() => handleItemClick(index)}
                    >
                        {isActive && <LoadingCircle progress={progress} />}
                        <div style={styles.iconContainer}>
                            {item.icon ? (
                                <img
                                    src={item.icon}
                                    alt={item.heading}
                                    width="35"
                                    height="35"
                                    style={{
                                        filter: isActive
                                            ? "none"
                                            : "grayscale(100%) opacity(0.5)",
                                    }}
                                />
                            ) : (
                                <GlobeIcon color={iconColor} />
                            )}
                        </div>
                        <div style={styles.contentContainer}>
                            <motion.div
                                style={styles.heading}
                                animate={{
                                    color: isActive ? "#6633BB" : "#B9BCC1",
                                }}
                                transition={{ duration: 0.3 }}
                            >
                                {item.heading}
                            </motion.div>
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{
                                    height: isActive ? "auto" : 0,
                                    opacity: isActive ? 1 : 0,
                                }}
                                transition={{
                                    duration: 0.3,
                                    ease: "easeInOut",
                                }}
                                style={{
                                    ...styles.content,
                                    overflow: "hidden",
                                    color: "#221144",
                                }}
                            >
                                {item.content}
                            </motion.div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

BenefitComponent.defaultProps = {
    data: [
        {
            heading: "Kapsamlı Müşteri Anlayışı",
            content:
                "Müşteri davranışlarını derinlemesine analiz ederek, işletmenizin hedef kitlesini daha iyi anlamanızı sağlıyoruz.",
            icon: "",
        },
        {
            heading: "Artırılmış Müşteri Sadakati",
            content:
                "Müşteri sadakatini artırmak için etkili stratejiler öğrenin. Memnuniyeti maksimize edecek ilişki yönetimi uygulamaları ve özel müşteri hizmetleri teknikleri ile sadık bir müşteri tabanı oluşturun ve elde tutun.",
            icon: "",
        },
        {
            heading: "Geliştirilmiş İletişim Becerileri",
            content:
                "Etkili iletişim tekniklerini öğrenerek müşterilerinizle daha güçlü bağlar kurun.",
            icon: "",
        },
        {
            heading: "Sürdürülebilir Büyüme Yöntemleri",
            content:
                "Uzun vadeli başarı için sürdürülebilir büyüme stratejileri geliştirin ve uygulayın.",
            icon: "",
        },
    ],
}

addPropertyControls(BenefitComponent, {
    data: {
        type: ControlType.Array,
        title: "Benefit Items",
        propertyControl: {
            type: ControlType.Object,
            controls: {
                heading: { type: ControlType.String, title: "Heading" },
                content: {
                    type: ControlType.String,
                    title: "Content",
                    displayTextArea: true,
                },
                icon: { type: ControlType.Image, title: "Icon" },
            },
        },
    },
})
